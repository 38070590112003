import React, { useState, useEffect } from 'react';
import './index.css';

const App = () => {
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setBalance((prevBalance) => prevBalance + 10);
        }, 10000); // 10000 milliseconds = 10 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="app__container">
            <div className="balance">Balance: ${balance}</div>
        </div>
    );
};

export default App;
